<template>
  <div>
    <el-dialog
      width="30%"
      title="创建节点"
      :visible.sync="addmenuVisible"
      append-to-body
      @close="addmenuVisibleClose"
      :before-close="addmenuVisibleClose"
      custom-class="addmenu-dialog"
      top="10%"
    >
      <div class="addmenu-container">
        <div class="addmenu-gridcontainer">
          <!-- 节点名称 -->
          <div class="addmenuItem">
            <span class="addmenu-title">节点名称:</span>
            <el-input v-model="input1" placeholder="请输入内容"></el-input>
          </div>
          <!-- 菜单路径 -->
          <div class="addmenuItem">
            <span class="addmenu-title">菜单路径:</span>
            <el-input v-model="input2" placeholder="请输入内容"></el-input>
          </div>
          <!-- 可用角色 -->
          <div class="addmenuItem clickBox">
            <span class="addmenu-title">可用角色:</span>
            <div class="addmenuclickBox">
              <el-checkbox
                :indeterminate="isIndeterminate"
                v-model="checkAll"
                @change="handleCheckAllChange"
                class="checkallBtn"
                >全选</el-checkbox
              >
              <div style="margin: 15px 0"></div>
              <el-checkbox-group
                v-model="checkedCities"
                @change="handleCheckedCitiesChange"
              >
                <el-checkbox v-for="city in cities" :label="city" :key="city">{{
                  city
                }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
          <!-- 状态 -->
          <div class="addmenuItem">
            <span class="addmenu-title">状态:</span>
            <el-radio v-model="radio" label="1">启用</el-radio>
            <el-radio v-model="radio" label="2">未启用</el-radio>
          </div>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="1">取消</el-button>
        <el-button @click="1" class="resBtn">创建</el-button>
      </div>
    </el-dialog>
  </div>
</template>


<script>
const cityOptions = ['管理员', '会计', '财务'];
export default {
  data() {
    return {
      checkAll: false,
      checkedCities: ['管理员'],
      cities: cityOptions,
      isIndeterminate: true,
      radio:'1',
      input1:'',
      input2:'',
    }
  },
  props: {
    addmenuVisible: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    addmenuVisibleClose() {
      console.log('内置dialog被关闭')
      this.$emit('addmenuVisibleClose')
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptions : [];
      this.isIndeterminate = false;
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.cities.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
    }
  }
}
</script>

<style lang="scss">
.addmenu-dialog {
  .el-dialog__header {
    background-color: $index-buttonPor-color;
    font-size: 20px;
    font-weight: bold;
    .el-dialog__title {
      color: #ffffff;
    }
    .el-dialog__headerbtn {
      font-size: 20px;
      color: #ffffff;
      .el-icon-close {
        color: #ffffff;
      }
    }
  }
  .el-dialog__body {
    height: 400px;
    overflow: auto;
    padding: 50px;
    .addmenu-gridcontainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-row-gap: 20px;
      grid-column-gap: 20px;
    }
    .clickBox{
        align-items: flex-start !important;
    }
    .addmenuItem {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .addmenu-title {
        width: 100px;
      }
      .el-input,
      .el-select {
        flex: 1;
      }
    }
  }
  .el-dialog__footer {
    .dialog-footer {
      .el-button {
        border: 1px solid $index-buttonPor-color;
        color: $index-buttonPor-color;
      }
      .resBtn {
        background-color: $index-buttonPor-color;
        color: #ffffff;
      }
    }
  }
}
</style>