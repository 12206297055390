<template>
  <div class="taskreleasetable-container">
    <el-table
      :data="tableData"
      style="width: 100%"
      class="taskreleasetable"
      stripe
    >
      <!-- 序号 -->
      <el-table-column type="index" align="center" label="ID" width="80" fixed>
      </el-table-column>

      <!-- 登录名 -->
      <el-table-column prop="menu_name" label="菜单名" align="center">
      </el-table-column>

      <!-- 邮箱 -->
      <el-table-column prop="menu_path" label="菜单路径" align="center">
      </el-table-column>
      <!-- 角色 -->
      <el-table-column prop="available_roles" label="可用角色" align="center">
        <template slot-scope="{ row }">
          <span v-for="item in row.available_roles" :key='item' class="available_rolesspan">{{item}}</span>
        </template>
      </el-table-column>

      <!-- 是否启用 -->
      <el-table-column
        prop="state"
        label="是否启用"
        width="100"
        align="center"
        :filters="[
          { text: '启用', value: '启用' },
          { text: '未启用', value: '未启用' },
        ]"
        :filter-method="filterTag"
        filter-placement="bottom-end"
      >
        <template slot-scope="scope">
          <el-tag
            :type="scope.row.state === '启用' ? 'primary' : 'success'"
            disable-transitions
            >{{ scope.row.state }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="180" align="center">
        <template slot-scope="scope">
          <el-button
            @click="handleClick(scope.row)"
            type="button"
            class="seebtn"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <editmenu-com
      :editmenuVisible="editmenuVisible"
      :rowdata="rowdata"
      @editmenuVisibleClose='editmenuVisibleClose'
    ></editmenu-com>
  </div>
</template>


<script>
import editmenuCom from './editmenuCom.vue'

export default {
  components: {
    editmenuCom
  },
  data() {
    return {
      editmenuVisible: false,
      rowdata: {},
      /**
       * 菜单名：menu_name
       * 菜单路径:menu_path
       * 可用角色:available_roles
       */
      //   最多13条数据可以展示在页面上  所以一页就是13条
      tableData: [{
        id: 1,
        menu_name: 'index',
        menu_path: '/index',
        available_roles: ['管理员', '会计','财务'],
        state: '启用'
      },

      ],
    }
  },
  props: {

  },
  methods: {
    handleClick(row) {
      console.log(row);
      this.rowdata = row
      this.editmenuVisible = true
    },
    taskdetailsVisibleClose() {
      this.taskdetailsVisible = false
    },
    editmenuVisibleClose() {
      this.editmenuVisible = false
    },
    filterTag(value, row) {
      return row.state === value;
    },
  }
}
</script>



<style lang="scss">
.taskreleasetable-container {
  height: 900px;
  overflow: hidden;
  .available_rolesspan{
      margin-left: 5px;
  }
  .available_rolesspan:first-child{
      margin-left: 0px;
  }
  .taskreleasetable {
    thead {
      th {
        color: #ffffff;
        background-color: $index-buttonPor-color;
      }
    }
    .seebtn {
      background-color: $index-buttonPor-color;
      color: #ffffff;
    }
    .withdrawbtn {
      background-color: $index-buttonerr-color;
      color: #ffffff;
    }
  }
}
</style>