<template>
  <!-- 管理员管理--菜单管理页 -->
  <div class="employee-management-container">
    <div class="addBtnBox">
      <el-button
        icon="el-icon-circle-plus-outline"
        class="addBtn"
        @click="addUserFunc"
        >添加</el-button
      >
    </div>
    <!-- 增删改查 -->
    <div class="managementBox">
      <el-button class="select">全部</el-button> <el-button>重置</el-button>
      <el-input
        v-model="input"
        placeholder="请输入内容"
        suffix-icon="el-icon-search"
      ></el-input>
    </div>

    <div class="table-container">
      <menumanagementtable-com></menumanagementtable-com>
    </div>
    <pagination-com></pagination-com>
    <!-- <addemployee-com
      :addemployeeVisible="addemployeeVisible"
      @addemployeeVisibleClose="addemployeeVisibleClose"
    ></addemployee-com> -->
    <addmenu-com
      :addmenuVisible="addmenuVisible"
      @addmenuVisibleClose="addmenuVisibleClose"
    ></addmenu-com>
  </div>
</template>




<script>
import menumanagementtableCom from '../components/menu-management/menumanagementtable.vue';
import paginationCom from '../components/paginationCom.vue'
import addmenuCom from '../components/menu-management/addmenuCom.vue'

export default {
  components: {
    menumanagementtableCom, paginationCom, addmenuCom
  },
  data() {
    return {
      addmenuVisible: false,
      // 客户名称或手机号
      customer_phone: '',
      // 联系人
      contacts: '',
      // 地址
      address: '',
      // 所属客服
      customer_service: '',
      value: '',
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      currentPage3: 1,

    }
  },
  methods: {
    addUserFunc() {
      this.addmenuVisible = true;
    },
    addmenuVisibleClose() {
      this.addmenuVisible = false;
    },
    handleCurrentChange() {

    },
    handleSizeChange() {

    },

  }
}
</script>


<style lang='scss'>
.employee-management-container {
  box-sizing: border-box;
  height: 100%;
  background-color: #f2f2f2;
  padding: 20px;

  .selectContainer {
    display: grid;
    grid-row-gap: 20px;
    // grid-column-gap: 25px;
    grid-template-columns: repeat(auto-fill, 400px);
    justify-content: space-between;
    .el-input__inner {
      display: block;
      width: 400px !important;
    }
    .el-date-editor--datetimerange {
      // grid-column-start:项目的起始列，
      // grid-column-start: 2;

      // grid-column-end:项目结束列，
      // grid-column-end: 4;

      // 元素的左边框距离右边框跨越2个网格
      grid-column-end: span 2;

      width: 100% !important;
      line-height: 0;
      .el-range-separator {
        color: $index-hui-color;
      }
      .el-range-input {
        width: 40%;
      }
    }
  }
  .managementBox {
    margin-top: 10px;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    .el-input {
      width: 300px;
      margin-left: 10px;
    }
    .el-button {
      width: 108px;
    }
    .select {
      background-color: $index-button-color;
      color: #ffffff;
    }
  }
  .addBtnBox {
    margin-top: 10px;
    text-align: left;
    .addBtn {
      width: 108px;
      background-color: #8674eb;
      color: #ffffff;
    }
  }
  .table-container {
    max-height: 900px;
    overflow: hidden;
    margin-top: 30px;
  }
  .pagination-container {
    margin-top: 30px;
    height: 40px;
    .el-pagination {
      height: 100%;
      box-sizing: border-box;
      color: #666666;
      .el-pager li {
        padding: 0 25px;
        font-size: 16px !important;
        height: 100%;
        line-height: 40px;
      }
      .el-pager li.active {
        color: #409eff;
      }
      .btn-prev,
      .btn-next {
        padding: 0 30px;
        height: 40px;
        text-align: center;
      }
      .btn-prev {
        border-radius: 10px 0 0 10px;
      }
      .btn-next {
        border-radius: 0px 10px 10px 0px;
      }
      .el-pagination__jump {
        .el-input {
          margin: 0 20px;
          font-size: 16px;
        }
      }
      button,
      span:not([class*="suffix"]) {
        height: 40px;
        line-height: 40px;
        font-size: 16px !important;
      }
    }
    .el-icon-arrow-left:before {
      content: "上一页";
      font-size: 16px;
    }
    .el-icon-arrow-right:before {
      content: "下一页";
      font-size: 16px;
    }
  }
}
</style>